import dispatchCustomEvent from "../components/custom-event/browser";

export const getParam = (param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

export const setParam = (param, value, options = {}) => {
  const {
    behavior = "pushState"
    /* "pushState" | "replaceState" | "refresh" */
  } = options;
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(param, value);

  if (["pushState", "replaceState"].includes(behavior)) {
    const str = searchParams.toString();
    const winHistParams = [
      { searchParams: str },
      `Set ${param} to ${value}`,
      `${window.location.pathname}?${str}`
    ];
    window.history[behavior](...winHistParams);
  } else window.location.search = searchParams;
};

// do things once per element once they intersect
export const callbackWhenInView = (props) => {
  const {
    elems,
    callback,
    callbackFalsy,
    root = null,
    rootMargin = "150px",
    threshold = 0,
    onceOnly = true // set to false if you want it to fire multiple times. Maybe some animation that happens each time it goes on/off screen or something
  } = props;

  if (!("IntersectionObserver" in window)) {
    // do thing instantly for those not supporting IO
    elems.forEach((elem) => {
      callback(elem);
    });
  } else {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback(entry.target, { observer, entry });
            if (onceOnly) {
              observer.unobserve(entry.target);
            }
          } else callbackFalsy?.(entry.target, { observer, entry });
        });
      },
      { root, rootMargin, threshold }
    );
    elems.forEach((elem) => observer.observe(elem));
  }
};

export const createTransactionID = () => {
  return (
    Math.round(new Date().getTime()) + "" + Math.floor(Math.random() * 10000)
  );
};

// A helper for escaping HTML
export { escape, unescape } from "html-escaper";

// Dynamically replaces strings with provided object data.
// Example:
// hotLoadStrings("My name is {name}. My car is {color}.", { color: "red", name:"fred"})
// becomes
// "My name is fred. My car is red"
// This allows publishers the ability to inject dynamic data into strings they provide via LDS Publisher when a given component is setup to use this utility.
export const hotloadStrings = (original, replacements, options = {}) => {
  const { replaceFullString = false, flags = "g" } = options;
  let result = original || "";
  Object.keys(replacements).map((objectKey) => {
    const varString = `{${objectKey}}`;
    const value = replacements[objectKey];
    if (!replaceFullString) {
      const reg = new RegExp(varString, flags);
      result = result.replace(reg, value);
    } else if (original.includes(varString)) result = value;
  });
  return result;
};

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

// Function to create a Globally Unique Identifier
export const makeGuid = () => {
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

// Create inique ID
export const makeID = () => {
  return `id${s4()}${s4()}`;
};

export const getComponent = (componentData) => {
  return new Promise((resolve) => {
    if (componentData && componentData.length) {
      const endpoint = `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/component`;
      const req = new XMLHttpRequest();
      req.addEventListener("load", () => {
        if (req.status === 200) {
          resolve(JSON.parse(req.responseText));
        } else {
          if (window.location.origin.match(/:\/\/(localhost|dev|test|stage)/)) {
            // eslint-disable-next-line no-console
            console.log(
              `%cFailed to load component API`,
              "font-size: 1.2rem; color: orangered; font-weight: bold;",
              { req, componentData }
            );
          }
          resolve("");
        }
      });
      req.open("POST", endpoint);
      req.setRequestHeader("Content-type", "application/json");
      req.send(JSON.stringify(componentData));
    } else {
      resolve("");
    }
  });
};
// https://javascript.info/event-delegation
export const eventDelegation = (
  wrappingElem, // only look within this element. Currently only allows a single element (doesn't loop), but could be added if needed and not be a breaking change. Just keeping extra code out until it is needed
  eventType, // event to fire on
  targetSelector, // element(s) to look for within the wrappingElem
  callback // what to fire when the event is triggered
) => {
  if (typeof wrappingElem === "string") {
    // if a string is passed, it isn't a dom node, so find it first
    wrappingElem = document.querySelector(wrappingElem);
  }

  wrappingElem.addEventListener(eventType, (event) => {
    // check if the element matches the selector or if a parent does. `closest` isn't supported by <=IE11, but at like .01% of our users, I think I'm ok with it - AB
    if (event.srcElement.closest(targetSelector)) {
      callback(event);
    }
  });
};

export const checkYoutube = async () => {
  const ytAvailable = sessionStorage.getItem("yt-available");
  if (ytAvailable === "available") return true;
  else if (ytAvailable === "unavailable") return false;
  else {
    const ico = new Image();
    ico.src = "https://www.youtube.com/favicon.ico";
    return new Promise((resolve) => {
      ico.addEventListener("load", () => {
        sessionStorage.setItem("yt-available", "available");
        resolve(true);
      });
      ico.addEventListener("error", () => {
        sessionStorage.setItem("yt-available", "unavailable");
        resolve(false);
      });
    });
  }
};

// Used in browser.js files to init components. A "data-type" can be passed to query for the elements that need to be inited, or a custom selector can be used as well.
// Setting the attribute "no-init" on a component will prevent it from being inited
// Setting the attribute "init-children='false'" on a parent component will prevent all children components from being inited
export const initComponent = (type, callback, customSelector) => {
  const initializedAttribute =
    type.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    }) + "Initialized";
  const selector = customSelector ? customSelector : `[data-type="${type}"]`;

  [
    ...document.querySelectorAll(
      `${selector}:not([no-init]):not([${initializedAttribute}]`
    )
  ].forEach((element) => {
    const parentPreventInit = element.closest('[init-children="false"]');
    if (!parentPreventInit) {
      element.setAttribute(initializedAttribute, "");
      callback(element);
    }
  });
};

export const containsComponent = (components, name) => {
  if (!Array.isArray(components)) {
    return false;
  }
  const found = components.find((component) => {
    // is the current node it? check for mo- prefix since it won't always be removed
    if (component?.type === name || component?.type === "mo-" + name) {
      return true;
    }
    // if not, does this node contain an additonal level?
    if (component?.components) {
      return containsComponent(component.components, name);
    }
  });
  return found ? true : false;
};

// Used for accessibility purposes. For components that have hidden elements, such as the Enhanced UI Slider and Card Stack, this will set the tabIndex on hidden elements to "-1" so that they won't be focusable when hidden
// When "setFocusOnSelected" is set to "true", selected visible elements will receive focus. If set to "false", they will not automatically receive focus
// When "setTabIndexOnSelected" is set to "true", the selected visible element will receive a tabIndex of "0" so that it can accessed by the keyboard
export const setTabIndex = (
  elements,
  selectedElementIndex,
  setFocusOnSelected,
  setTabIndexOnSelected
) => {
  elements.forEach((element, index) => {
    const isSelected = index === selectedElementIndex;
    element.setAttribute("hideTabbableElems", !isSelected);
    const tabbableElemTypes = ["a", "button", "input", "textarea", "select"];
    // Finds the closest parent drawer and checks to see if the drawer in question is open or closed
    const parentDrawerIsOpen =
      element
        .closest("[data-type='drawer-body']")
        ?.getAttribute("hideTabbableElems") === "false";

    // Need to check that there aren't any nested components that also have hidden tabbable elements.
    // For example, if there is a drawer within a drawer, when the parent drawer is open, you still want to remove the focus from the hidden tabbable items inside of the nested closed drawer
    const nestedHiddenTabbableElems =
      element.querySelectorAll("[hideTabbableElems='true']") || [];
    const tabbableElems = [
      ...element.querySelectorAll(tabbableElemTypes.join(", "))
    ];
    tabbableElems.push(element);

    // If the element isn't selected, set tabindex to -1 if it is interactive and on any interactive child elements to prevent tabbing
    tabbableElems.forEach((e) => {
      toggleTabIndex(
        e,
        parentDrawerIsOpen,
        nestedHiddenTabbableElems,
        isSelected
      );
    });
    // Give focus to the element as a whole
    if (isSelected && (setFocusOnSelected || setTabIndexOnSelected)) {
      element.setAttribute("tabindex", "0");
      if (setFocusOnSelected) {
        setTimeout(() => {
          element.focus();
        }, 100);
      }
    }
  });
};

function toggleTabIndex(
  e,
  parentDrawerIsOpen,
  nestedHiddenTabbableElems,
  isSelected
) {
  let insideNestedElem = false;
  // Only remove/add the tabindex if element isn't inside of an element that has hidden tabbable elements
  [...nestedHiddenTabbableElems].forEach((nestedElem) => {
    if (nestedElem.contains(e)) insideNestedElem = true;
  });
  if (!insideNestedElem) {
    (isSelected && parentDrawerIsOpen === undefined) ||
    (isSelected && parentDrawerIsOpen)
      ? e.removeAttribute("tabindex")
      : e.setAttribute("tabindex", "-1");
  }
}

export const copyToClipboard = (value, linkElement, callback) => {
  const input = document.createElement("input");
  input.value = value;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
  linkElement.focus();
  callback();
};

export const toggleTrueFalseAttribute = (element, attribute) => {
  const newValue =
    element.getAttribute(attribute) === "true" ? "false" : "true";
  element.setAttribute(attribute, newValue);
};

// Dynamically sets up styles for the designated type and size (i.e. body and desktop).
// Could be extracted for a separate component like invertTextColor if necessary
export const styleSetup = (type, size, element) => {
  // Find the element by its data tag
  let textElement = element.querySelector(`[data-${type}-style-${size}]`);
  // Create a list from the styles in the dataset
  let customClass = textElement?.dataset[`${type}Style${size}`]
    ?.trim()
    ?.split(" ");
  let elementClasses = textElement?.classList;
  // We need this custom toggleClasses function in case our customClass list contains more than one item
  // Specifically, this is important because of the gradient Overlay stylings present in the Uber tile
  // As of writing this code, there is no built in JS method to toggle all the classes present in a list
  const toggleClasses = (element, classLists) =>
    classLists.map((classList) => element.classList.toggle(classList));
  if (!textElement || !customClass || !elementClasses) return;

  // Sets up the style based on current window size
  let isMobile = window.innerWidth < 600;
  if (isMobile === (size === "Mobile")) {
    toggleClasses(textElement, customClass);
  }

  // This will trigger when the window changes sizes to update the style
  window.addEventListener("resize", () => {
    if (window.innerWidth < 600 !== isMobile) {
      isMobile = !isMobile;
      toggleClasses(textElement, customClass);
    }
  });
};

// Analytics function called in audio-player, media-player, and the video components
export const sendMediaAnalytics = (details) => {
  const { playerElement, event, detail } = details;

  dispatchCustomEvent(playerElement, event, {
    detail,
    bubbles: true,
    cancelable: false
  });
};

export const isOnMobile = () => {
  try {
    return (
      window.innerWidth < 720 &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  } catch (e) {
    return false;
  }
};
