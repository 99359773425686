import { getParam, initComponent } from "../../utilities/common";
import ChurchProductMap from "../church-product-map/browser";
import ChurchProductFilter from "../church-product-filter/browser";
import Autocomplete from "../autocomplete/browser";
import GeolocateBtn from "../geolocate-btn/browser";
import ChurchProductResults from "../church-product-results/browser";

export default class ChurchProduct {
  constructor(element) {
    this.element = element;
    this.mapElement = element.querySelector('[data-type="church-product-map"]');
    this.resultsElement = element.querySelector(
      '[data-type="church-product-results"]'
    );
    this.spinner = element.querySelector("[data-church-product-spinner]");
    this.resultsHeaderWrapper = element.querySelector('[data-type="heading"]');
    this.resultsHeader = this.resultsHeaderWrapper.querySelector("h3");
    this.filterElement = element.querySelector(
      '[data-type="church-product-filter"]'
    );

    this.setUpNarrDown();

    this.cpMap = new ChurchProductMap(this.mapElement, this.spinner);
    this.cpFilter = new ChurchProductFilter(this.filterElement);
    this.cpResults = new ChurchProductResults(this.resultsElement);
    window.addEventListener("load", () => this.hideSpinner());

    element.addEventListener("exact-address", (e) => {
      if (e.detail.data) this.hideNarrDown();
      else this.showNarrDown();
    });

    element.addEventListener("search-from-filter", () =>
      this.updateSearch({ noAnalytics: true })
    );

    element.addEventListener("loading-search-results", () => {
      this.showSpinner();
    });

    element.addEventListener("new-search-results", (e) => {
      if (this.cpMap.searchField.value !== this.narrDown.input.value)
        this.narrDown.input.value = "";
      if (this.narrDown.opener.getAttribute("aria-expanded") === "true")
        this.narrDown.opener.click();
      this.showFilter();
      this.renderResults(e.detail.data);
    });

    element.addEventListener("clicked-map-marker", (e) => {
      this.cpResults.highlightResults(e.detail.data);
    });

    element.addEventListener("clicked-unit-result", (e) => {
      this.cpMap.selectMarkerByIndex(e.detail.data);
    });

    window.addEventListener("popstate", () => this.processInitialUrl());

    this.processInitialUrl();
  }

  static getUnit(elem) {
    return elem
      ?.closest('[data-type="drawer-opener"]') // find the right parent wrapper
      ?.querySelector("[data-church-unit-id]") // then go back down to the elem that has the unitId
      ?.getAttribute("data-church-unit-id"); // and get the unitId
  }

  showSpinner() {
    this.spinner.setAttribute("data-spinner-visible", "");
  }

  hideSpinner() {
    this.spinner.removeAttribute("data-spinner-visible");
  }

  updateSearch(options) {
    if (this.narrDown.input) {
      this.cpMap.searchField.value =
        this.narrDown.input.value.trim() || this.cpMap.searchField.value;
      this.cpMap.filterData = this.cpFilter.filterData;
      this.cpMap.executeSearch(options);
      this.hideHeader();
    }
  }

  renderResults(resultsFromMap) {
    const translatedStrings = JSON.parse(
      decodeURIComponent(this.element.dataset.translatedStrings)
    );

    this.cpResults.render(resultsFromMap);

    if (resultsFromMap.length === 0) {
      this.resultsHeader.innerHTML = translatedStrings.noResultsString;
      this.hideNarrDown();
    } else if (resultsFromMap.length === 1) {
      this.resultsHeader.innerHTML = translatedStrings.singleResult;
    } else if (resultsFromMap.length > 1) {
      this.resultsHeader.innerHTML = translatedStrings.multipleResults;
    }
    this.showHeader();
    setTimeout(() => this.hideSpinner());
  }

  processInitialUrl() {
    this.cpFilter.processInitialURL();
    this.cpMap.filterData = this.cpFilter.filterData;

    const locationParam = getParam("location");
    if (locationParam || this.cpMap.autoGeolocate) {
      this.cpMap.processInitialSearch();
    } else {
      this.showHeader();
    }
  }

  showFilter() {
    this.filterElement.style.display = "block";
  }
  showHeader() {
    // using visibility to take up space to eliminate some block shifting
    this.resultsHeaderWrapper.style.visibility = "visible";
  }
  hideHeader() {
    this.resultsHeaderWrapper.style.visibility = "hidden";
  }
  showNarrDown() {
    this.narrDown.container.style.display = "block";
  }
  hideNarrDown() {
    this.narrDown.container.style.display = "none";
  }

  setUpNarrDown() {
    this.narrDown = {};
    this.narrDown.container = this.element.querySelector(
      '[data-type="drawers"]'
    );
    this.narrDown.opener = this.narrDown.container.querySelector("button");
    this.narrDown.input = this.narrDown.container.querySelector("input");
    this.narrDown.geoElm = this.narrDown.container.querySelector(
      '[data-type="geolocate-btn"]'
    );
    this.narrDown.autocomplete = this.narrDown.container.querySelector(
      '[data-type="autocomplete"]'
    );
    this.narrDown.searchBtn = this.narrDown.container.querySelector(
      'button[data-type="button"]'
    );

    const smartyStreets = (string) => {
      return new Promise((resolve) => {
        const req = new XMLHttpRequest();
        req.addEventListener("load", () => {
          if (req.status === 200) {
            const options = req.response;
            resolve(options);
          } else {
            resolve([]);
          }
        });
        req.open(
          "GET",
          `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/autocomplete?address=` +
            encodeURIComponent(string)
        );
        req.responseType = "json";
        req.send();
      });
    };

    new Autocomplete({
      inputEl: this.narrDown.input,
      optionsContainer: this.narrDown.autocomplete,
      maxOptions: 10,
      returnNewOptionsArr: () => smartyStreets(this.narrDown.input.value),
      onReturnKey: () => this.updateSearch(),
      onSelectOption: () => this.updateSearch(),
      optionToString: (option) => option.text,
      cancelBrowserAutoComplete: true
    });

    if (this.narrDown.geoElm) {
      new GeolocateBtn({
        element: this.narrDown.geoElm,
        onPositioned: (position) => {
          this.narrDown.input.value = `${position.coords.latitude}, ${position.coords.longitude}`;
          this.updateSearch();
        },
        handleNoBrowserSupport: () =>
          this.cpMap.createBrowserAlert("browserLocation"),
        handleUserBlockedGeo: () =>
          this.cpMap.createBrowserAlert("userLocation")
      });

      this.narrDown.searchBtn.addEventListener("click", () => {
        this.updateSearch();
      });
    }
  }
}

export const init = () => {
  initComponent("church-product", (element) => new ChurchProduct(element));
};
init();
