import {
  initComponent,
  copyToClipboard,
  toggleTrueFalseAttribute
} from "../../utilities/common.js";
import { getHeading } from "../../utilities/analytics";
import { remoteComponentPublish } from "../remote-trigger/browser.js";

export function changeActionBarText({ actionBar, text }) {
  const pageURL = actionBar.dataset.pageUrl;
  const twitter = actionBar.querySelector('[data-social-icon-type="twitter"]');
  const twitterLink = `https://twitter.com/intent/tweet?url=${pageURL}&text=${
    text ? encodeURIComponent(text) : ""
  }`;

  twitter.href = twitterLink;
  twitter.dataset.twitterText = text;
}

export default class ActionBar {
  constructor(element) {
    this.shareBtn = element.querySelector("[data-share-icon]");
    this.shareBtn?.addEventListener("click", () => this.expandShareMenu());

    element.addEventListener("click", (e) =>
      ActionBar.detectSocialMediaClick(e)
    );
  }

  static detectSocialMediaClick(clickEvent) {
    const icon = clickEvent.target.closest("[data-social-icon-type]");
    if (icon) {
      // Capitalize the icon name and use it as the channel value below (mail will be converted into "Email")
      const iconType =
        icon.dataset.socialIconType === "mail"
          ? "Email"
          : icon.dataset.socialIconType.charAt(0).toUpperCase() +
            icon.dataset.socialIconType.slice(1);
      const event =
        window.digitalDataEventsCUC.component[
          icon.getAttribute("interactionType") === "following"
            ? "follow"
            : "share"
        ];
      window.digitalData.push({
        event,
        component: {
          info: {
            channel: iconType,
            link: window.location.href,
            title: getHeading(icon)
          },
          category: {
            primary: "social media"
          }
        }
      });

      if (iconType === "Copy-link") {
        copyToClipboard(icon.dataset.copylink, icon, () => {
          const div = document.createElement("div");
          div.dataset.remoteTriggerId = icon.dataset.rtid;
          remoteComponentPublish(div);
        });
      }
    }
  }

  expandShareMenu() {
    toggleTrueFalseAttribute(this.shareBtn, "aria-expanded");
  }
}
export const init = () => {
  initComponent("action-bar", (element) => new ActionBar(element));
};
init();
