// TODO find a way to select form elements without importing classes into javascript
import styles from "./styles.css";
import formStyles from "../form-components/form-styles/styles.css";
import DDO from "../ddo/browser";
import { setupPlayer } from "../media-player/browser";
import { setUpEnhancedSlider } from "../enhanced-ui-slider/browser";
import DOMPurify from "dompurify";
import {
  initComponent,
  createTransactionID,
  setTabIndex
} from "../../utilities/common";
import hashFnv32a from "../hashfnv32a/browser.js";
import { scrollIntoViewIfNeeded } from "../scroll-logic/browser";
import formDataEntries from "../form-components/formEntries";

const cookieName = "_mkto_trk";

/*
 * checkValues fires when trying to move to the next step.
 * It accepts a callback to be run after the inputs are validated.
 * Stepped Form finds all the inputs on that step, and asks for their validation.
 * Validations can be either true/false, or in the case of service call validation,
 * promises that have to resolve or reject.
 * If any input returns false or rejects a promise, the callback passed to
 * checkValues does not run.
 *
 * changeStep handles the background changes of going to a new step.
 * changeRegion handles the hiding and revealing of the steps.
 * updateVerificationStep handles updating the values on the verification page.
 *
 * The static methods resetStyles, modifyInput, checkValidationTarget, and
 * checkValidation control the validation styling of inputs.
 * */

export default class SteppedForm {
  constructor(element, offerTypeOverride = "") {
    this.element = element;
    this.form = element.querySelector("form");
    // Dynamically assigns all the properties from the config on the index side to this.whateverVariable variables for usage in the browser (rather than doing this.thing = config.thing over and over)
    Object.assign(this, JSON.parse(this.element.dataset.config));

    this.stepHeader = this.element.querySelector(`.${styles.stepHeader}`);
    this.stepCounter = this.element.querySelector(`.${styles.stepCounter}`);
    // stepCounter is tabbable to allow screen readers to view the step number
    if (this.stepCounter) {
      setTabIndex([this.stepCounter], 0);
    }
    this.currentStep = 1;

    this.regions = [
      ...this.element.querySelectorAll(
        `[${
          this.isExpandingDrawer
            ? "data-type='drawers-container'"
            : "data-form-region"
        }]`
      )
    ];
    if (this.isExpandingDrawer) {
      this.regionTriggers = this.regions.map((region) =>
        region.querySelector("[data-type='drawer-opener']")
      );
      this.regionDrawers = this.regions.map((region) =>
        region.querySelector("[data-type='drawer-body']")
      );
      this.currentDrawer = this.regionDrawers[0];
      this.regionRemoteTriggerIds = this.regionTriggers.map(
        (region) => region.dataset.remoteTriggerId
      );
    }

    this.currentRegion = this.regions[0];

    this.verificationRegions = [
      ...this.element.querySelectorAll(`[data-form-verification-region]`)
    ];
    this.reachedSteps = [];
    this.loggedSteps = []; // track what steps are logged so we don't log the same one multiple times if they visit it multiple times

    this.offerType =
      offerTypeOverride || this.form?.querySelector(`[name=offerType]`)?.value;
    this.sourceId = this.form?.querySelector(`[name=sourceId]`)?.value;
    this.steppedFormHeading =
      this.element?.querySelector(`.${styles.formHeading}`)?.innerText ||
      `${this.offerType} Stepped Form`;
    this.started = false;
    this.formSubmitted = false;
    this.isSimpleSignUp =
      this.form?.querySelector(`[name=formType]`)?.value === "simple-sign-up";
    this.submissionRedirect = JSON.parse(
      this.element.getAttribute("submissionRedirect")
    );

    this.submitButton = this.element.querySelector(
      `[data-type="form-submit-button"]`
    );

    this.usePreFillData = this.form.hasAttribute("usePreFillData");
    this.analyticsFormData = {
      name: this.steppedFormHeading,
      id: this.sourceId,
      formType: this.offerType
    };

    this.setupButtonListeners();
    this.changeStep(this.currentStep, false);
    this.setupGeneralInputValidation();

    let cookie = document.cookie
      .split(";")
      .filter((cookie) => cookie.includes(cookieName))[0];

    if (cookie) {
      cookie = cookie.trim();

      element.querySelector("[name=munchkinId]").value = cookie.substring(
        cookie.indexOf(":") + 1,
        cookie.indexOf("&")
      );
      element.querySelector("[name=marketoToken]").value = cookie.substring(
        cookieName.length + 1
      );
    }
  }

  isFinalStep = (step) => step === this.numSteps;

  setDrawerValidity(drawer, validity) {
    if (this.isExpandingDrawer && drawer) drawer.setValid(validity);
  }

  submitHandler(event) {
    event.preventDefault();

    const validatedSteps = [
      ...this.regions,
      ...(this.isExpandingDrawer
        ? [this.form.querySelector("[data-form-region]")]
        : [])
    ].map((r, index) => {
      return new Promise((resolve, reject) => {
        this.checkValues(
          () => {
            this.setDrawerValidity(this.regionDrawers?.[index], true);
            resolve();
          },
          () => {
            this.setDrawerValidity(this.regionDrawers?.[index], false);
            // This index allows us to navigate back to the step that failed validation.
            reject(index);
          },
          r,
          this.isFinalStep(index + 1) // logAnalytics is only true if we're checking values on the last "region" (because the others have already been logged)
        );
      });
    });
    // Switched from Promise.all to Promise.allSettled because sometimes certain steps would "validate" faster than other ones and it take the user to the wrong step
    Promise.allSettled(validatedSteps).then((results) => {
      const failedIndex = results.find(
        (result) => result.status === "rejected"
      )?.reason;
      const lastIndex = this.numSteps - 1;
      if (failedIndex >= 0) {
        this.navigate(null, failedIndex + 1, this.regions[failedIndex], true);
        if (this.isExpandingDrawer)
          this.regionDrawers[lastIndex].setValid(false);
      } else {
        !this.formSubmitted && this.submitForm();
      }
    });
  }

  submitData(transactionID, data) {
    const req = new XMLHttpRequest();
    const endpoint = this.isSimpleSignUp ? "simple-subscription" : "forms";
    const requestHeader = this.isSimpleSignUp
      ? "application/json"
      : "application/x-www-form-urlencoded";
    // Open a request and change the endpoint based on if it's a simple-sign-up or another form
    req.open(
      "POST",
      `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/${endpoint}`
    );
    req.setRequestHeader("Content-type", requestHeader);
    req.onreadystatechange = () => {
      if (req.status === 200 || req.status === 201) {
        if (req.readyState === 4) {
          SteppedForm.sendAnalytics(
            { ...this.analyticsFormData, transactionID },
            "formSubmit"
          );
        }
        SteppedForm.displaySubmissionState(this.element, true);
      } else if (req.status !== 200) {
        if (req.readyState === 4) {
          SteppedForm.sendAnalytics(
            {
              ...this.analyticsFormData,
              error: "Server error. Data was not received."
            },
            "formError"
          );
        }
        SteppedForm.displaySubmissionState(this.element, false);
      }
    };

    req.send(data);
  }

  submitForm() {
    this.formSubmitted = true;
    const transactionID = createTransactionID();
    DDO(transactionID);
    let currentTime = transactionID.slice(0, 13);
    let hashed = hashFnv32a(currentTime, true, "WuN9P62Arf2E");
    const formData = formDataEntries(this.form);

    formData.push(["transactionId", transactionID]);
    formData.push(["hashed", hashed]);
    formData.push([
      "adobeId",
      window._satellite?.getVar("user_experience_cloud_id") || ""
    ]);

    SteppedForm.getCampaignIds(formData);

    let encodedData = Array.from(formData, (e) =>
      e.map(encodeURIComponent).join("=")
    ).join("&");

    let dataObj = formData.reduce((prev, curr) => {
      return { ...prev, [curr[0]]: curr[1] };
    }, {});

    // If Simple Sign up, add data and use different API endpoint
    if (this.isSimpleSignUp)
      dataObj = SteppedForm.simpleSignUpDataAlteration(dataObj);

    this.submitData(
      transactionID,
      this.isSimpleSignUp ? JSON.stringify(dataObj) : encodedData
    );

    if (this.submissionRedirect) {
      let { URL, newTab } = this.submissionRedirect;
      window.open(URL, newTab);
    }
  }

  changeStep(step, focus = false, remoteOpening = false) {
    if (this.showVerification) this.updateVerificationStep(this.currentStep);

    // If the current step is somehow below or above the current number of steps, we reset it
    const stepCap = step > this.numSteps ? this.numSteps : step;
    this.currentStep = step < 1 ? 1 : stepCap;

    if (this.isExpandingDrawer) {
      this.changeDrawer(this.currentStep, remoteOpening);
      return;
    }

    // i.e. Step 1 of 2
    this.stepCounter &&
      (this.stepCounter.innerHTML = `${
        this.stepWord ? `${this.stepWord} ` : ""
      }${this.currentStep} / ${this.numSteps}`);

    const hideInitially = (element) => {
      element?.[this.currentStep === 1 ? "setAttribute" : "removeAttribute"](
        "data-hidden",
        ""
      );
    };

    // The backButton should be hidden on the first step
    hideInitially(this.backButton);

    // The stepHeader should be hidden on the first step if hideStepsInitially is true
    if (this.hideStepsInitially) {
      hideInitially(this.stepHeader);
    }

    this.changeRegion(this.currentStep, focus);
  }

  changeRegion(step, focus) {
    this.currentRegion.hidden = true;
    this.currentRegion = this.regions[step - 1];
    this.currentRegion.hidden = false;
    // Only focus the selectBox if the user is keyboarding through the form
    if (focus) this.stepCounter?.focus();
  }

  changeDrawer(step, remoteOpening) {
    this.currentRegion = this.regions[step - 1];
    const newDrawer = this.regionDrawers?.[this.currentStep - 1];
    // If they're not on the drawer or something doesn't trigger it, toggle it
    if (this.currentDrawer !== newDrawer || !remoteOpening) {
      this.currentDrawer = newDrawer;
      this.currentDrawer.toggle();
    }

    // If the user skips a step, validate all previous steps
    this.regionDrawers.forEach((drawer, index) => {
      if (index >= step - 1) return;
      this.checkValues(
        () => {
          this.setDrawerValidity(drawer, true);
        },
        () => {
          this.setDrawerValidity(drawer, false);
        },
        this.regions[index]
      );
      this.updateVerificationStep(index + 1);
    });

    // If it's the final step, validate it as well
    if (this.isFinalStep(step)) {
      this.currentDrawer.setValid(this.finalDrawerValidation());
    }
    this.currentDrawer.focus();
  }

  trackStep(logAnalytics) {
    // Only log the completion if they haven't completed it previously
    if (!this.loggedSteps.includes(this.currentStep) && logAnalytics) {
      const steppedFormStepCompletedText = this.titles[this.currentStep - 1];
      const steppedFormStepCompletedNumber = this.currentStep;
      const steppedFormTotalSteps = this.numSteps;
      this.loggedSteps.push(this.currentStep);

      SteppedForm.sendAnalytics(
        {
          ...this.analyticsFormData,
          stepName: steppedFormStepCompletedText,
          stepNumber: `${steppedFormStepCompletedNumber}/${steppedFormTotalSteps}`
        },
        "formStepComplete"
      );
    }
  }

  checkValues(resolve, reject, region, logAnalytics = true) {
    const validationRegion = region || this.currentRegion;

    // Grab all inputs AND select-groups
    const inputs = [
      ...validationRegion.querySelectorAll(
        `.${formStyles.inputField}, [data-type="select-group"]`
      )
    ];

    // Validate inputs
    inputs.forEach((input) => SteppedForm.checkValidation(input));

    const firstInvalidField = inputs.find(
      (i) => i.dataset.validity === "false"
    );

    // When user tries to proceed through the form, force the window to scroll to the first invalid field from the top of the form
    if (firstInvalidField) {
      scrollIntoViewIfNeeded(
        firstInvalidField.previousElementSibling || firstInvalidField,
        {
          block: "center",
          behavior: "smooth"
        }
      );
    }

    const validations = [
      ...validationRegion.querySelectorAll(`.${formStyles.formInputs}`)
    ]
      .map((e) => (e.checkIsValid ? e.checkIsValid() : "skip"))
      .filter((v) => v !== "skip" && v !== true); // No need to process validations that came back 'True'

    if (validations.length === 0) {
      this.trackStep(logAnalytics);
      resolve();
      return;
    }

    let allInputsValid = true;
    let promises = validations.map((validation) => {
      // If it's valid, it becomes a promise
      if (validation) {
        return validation;
      } else allInputsValid = false;
    });

    let invalidInputs = inputs
      .filter(Boolean)
      .filter((i) => !i.checkValidity?.())
      .map((i) => i.name);

    if (invalidInputs.length === 0) {
      invalidInputs = invalidInputs.concat(
        [...validationRegion.querySelectorAll("[data-type=heading]")].map(
          (heading) => heading.innerText
        )
      );
    }

    const errorAnalytics = { ...this.analyticsFormData, error: invalidInputs };

    Promise.allSettled(promises).then((data) => {
      const errors = data.filter((datum) => datum.status === "rejected");
      if (allInputsValid && !errors.length) {
        this.trackStep(logAnalytics);
        resolve();
      } else {
        SteppedForm.sendAnalytics(errorAnalytics, "formError");
        reject?.(errors.find((error) => error.reason)?.reason);
      }
    });
  }

  updateVerificationContent(values, step) {
    let hasErrors = false;
    const content = [
      ...new Set( // making it a set will remove duplicates
        values.map((v) => {
          if (v?.error) hasErrors = true;
          if (!Array.isArray(v.label)) v.label = [v.label];
          return v.label
            .map(
              (item) =>
                `<div${
                  v?.error ? ` class="${styles.missingValue}"` : ""
                }>${DOMPurify.sanitize(item, {
                  ALLOWED_TAGS: []
                })}</div>`
            )
            .join("");
        })
      )
    ].join("");

    if (content) {
      let template = document.createElement("template");
      const region = this.verificationRegions[step - 1];
      const values = region.querySelector(`.${styles.verificationValues}`);
      const headerAlert = region.querySelector(
        `.${styles.verificationRegionHeader} [data-icon-name='alert']`
      );

      // Show icon
      if (hasErrors && headerAlert?.hidden) headerAlert.hidden = false;
      // Hide icon
      else if (!hasErrors) headerAlert.hidden = true;

      template.innerHTML = `<div>${content.trim()}</div>`;
      while (values.firstChild) {
        values.removeChild(values.firstChild);
      }
      values.appendChild(template.content.firstChild);
    }
  }

  updateVerificationStep(step) {
    const values = !this.isFinalStep(step) // It's not the final step
      ? [
          ...this.regions[step - 1].querySelectorAll(
            `.${formStyles.formInputs} input, .${formStyles.formInputs} select, [data-type='select-group']`
          )
        ]
          .map((input) => SteppedForm.createReport(input))
          .filter((value) => value?.label)
      : [];

    // The expanding drawer form only updates the verification step once that drawer is opened, and thus the function ends early for that form type
    if (!values?.length && !this.isExpandingDrawer) return;
    this.updateVerificationContent(values, step);
  }

  navigate = (event, stepIndex, elemToScrollTo, remoteOpening) => {
    this.changeStep(stepIndex, event?.detail === 0, remoteOpening);
    scrollIntoViewIfNeeded(elemToScrollTo, { behavior: "smooth" });
  };

  finalDrawerValidation() {
    // If if the verificationStep is showing, it's the right step, and there's errors OR every value isn't filled - it's invalid
    return (
      !this.showVerification ||
      !this.isFinalStep(this.currentStep) ||
      ([
        ...this.currentRegion.querySelectorAll(`.${styles.verificationValues}`)
      ].every((node) => node.childNodes.length) &&
        !this.currentRegion.querySelector(`.${styles.missingValue}`))
    );
  }

  setupExpandingDrawerListeners(regionTriggers) {
    regionTriggers?.forEach((trigger, index) => {
      trigger?.addEventListener("click", (event) => {
        event.preventDefault();
        this.checkValues(
          () => {
            this.setDrawerValidity(
              this.currentDrawer,
              this.finalDrawerValidation()
            );
            this.navigate(event, index + 1, trigger);
          },
          (error) => {
            this.setDrawerValidity(this.currentDrawer, false);
            if (error?.stay !== true)
              // in certain cases (modal/slower verification) we reject with the value "stay" set to true to stop navigation from occurring too soon
              this.navigate(event, index + 1, trigger);
          },
          this.currentRegion
        );
      });
    });
  }

  nextButtonListener(event) {
    event.preventDefault();
    this.checkValues(
      () => {
        this.setDrawerValidity(
          this.currentDrawer,
          this.finalDrawerValidation()
        );
        this.navigate(
          event,
          this.currentStep + 1,
          this.currentDrawer || this.stepCounter
        );
      },
      () => {
        this.setDrawerValidity(this.currentDrawer, false);
      },
      this.currentRegion
    );
  }

  editButtonListener(event, button) {
    if (this.isExpandingDrawer) {
      this.currentDrawer.setValid(this.finalDrawerValidation());
    }
    const step = parseInt(button.dataset.region);
    this.navigate(event, step, this.regions[step - 1]);
  }

  setupButtonListeners() {
    this.backButton = this.element.querySelector(`.${styles.backButton}`);
    this.nextButtons = [
      ...this.element.querySelectorAll(`.${styles.nextButton}`)
    ];
    this.submitButton = this.element.querySelector('[type="submit"]');
    this.editButtons = this.element.querySelectorAll(
      `.${styles.verificationEditButton}`
    );
    let errorMessage = this.element.querySelector(`[data-form-error]`);
    let errorButton = errorMessage?.querySelector(`[data-type="button"]`);

    this.backButton?.addEventListener("click", (event) => {
      event.preventDefault();
      this.navigate(event, this.currentStep - 1, this.stepCounter);
    });

    this.nextButtons?.forEach((b) => {
      b?.addEventListener("click", (event) => this.nextButtonListener(event));
    });

    if (this.isExpandingDrawer)
      this.setupExpandingDrawerListeners(this.regionTriggers);

    this.submitButton?.addEventListener("click", (event) => {
      event.preventDefault();
      this.submitHandler(event);
    });

    this.editButtons?.forEach((b) => {
      b.addEventListener("click", (event) => this.editButtonListener(event, b));
    });

    errorButton?.addEventListener("click", () => {
      this.hideErrorState(this.element);
    });
  }

  setupGeneralInputValidation() {
    [
      ...this.form.querySelectorAll(`.${formStyles.inputField}, [form-element]`)
    ].forEach((e) => {
      e.addEventListener("blur", SteppedForm.checkValidationTarget);
      e.addEventListener("input", (e) => {
        SteppedForm.modifyInput(e, this.started, {
          name: this.steppedFormHeading,
          id: this.sourceId,
          formType: this.offerType
        });
        this.started = true;
      });
      e.addEventListener("focus", SteppedForm.resetStyles);

      if (this.usePreFillData) {
        e.value = sessionStorage.getItem(`saved-${e.name}`) || e.value;
      }
    });
  }

  hideErrorState(container) {
    container.classList.remove(styles.formError);
    container.querySelector("[data-form-body]").hidden = false;
    container.querySelector("[data-form-error]").hidden = true;
    this.formSubmitted = false; // This enables the user to submit the form again, after "trying again"
    scrollIntoViewIfNeeded(container, { behavior: "smooth" });

    // If the form is Simple Sign Up, removed attribute for styling purposes
    if (this.isSimpleSignUp) container.removeAttribute("formSubmitted");
  }

  static resetStyles(event) {
    event.target.classList.remove(formStyles.valid, formStyles.invalid);
    this.form.dataset.displayHiddenFields = "true"; // Show hidden fields when user enters
  }

  static getBoncomCid = () => {
    return document.cookie
      ?.split("; ")
      ?.find((str) => str.startsWith("CID="))
      ?.split("=")[1]; // Borrowed from MDN: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#example_2_get_a_sample_cookie_named_test2
  };

  static modifyInput(event, formStarted, analyticsFormData) {
    let target = event.target;
    if (
      !target.dataset.changed &&
      !target.dataset.userModified &&
      !formStarted
    ) {
      // Sends form start data to the Data Layer for Adobe Launch
      SteppedForm.sendAnalytics(analyticsFormData, "formStart");
    }
    target.dataset.changed = "true";
    target.dataset.userModified = "true";
  }

  static checkValidationTarget(event) {
    let target = event.target;
    target.value = target.value.trim();
    SteppedForm.checkValidation(target);
  }

  static sendAnalytics(formData, event) {
    // Sends form data to the Data Layer for Adobe Launch
    window.digitalData.push({
      event: window.digitalDataEventsCUC.component[event],
      component: {
        info: formData,
        category: {
          primary: "form"
        }
      }
    });
  }

  static checkValidation(target) {
    if (target?.checkValidity?.()) this.setAsValid(target);
    else this.setAsInvalid(target);
  }

  static setAsValid(element) {
    element.classList.add(formStyles.valid);
    element.classList.remove(formStyles.invalid);
    element.dataset.validity = "true";
  }

  static setAsInvalid(element) {
    element.classList.add(formStyles.invalid);
    element.classList.remove(formStyles.valid);
    element.dataset.validity = "false";
  }

  static displaySubmissionState(container, successfulSubmission) {
    container.querySelector("[data-form-body]").hidden = true;
    const queryClass = successfulSubmission
      ? `[data-form-confirmation]`
      : `[data-form-error]`;
    const formSubmittedMessage = container.querySelector(queryClass);

    if (formSubmittedMessage) formSubmittedMessage.hidden = false;

    if (successfulSubmission) {
      [
        ...formSubmittedMessage.querySelectorAll(
          "[data-type='ui-slider-wrapper']"
        )
      ].forEach((slider) => {
        setUpEnhancedSlider(slider);
        [...slider.querySelectorAll('[data-type="media-player"]')].forEach(
          setupPlayer
        );
      });
    } else {
      container.classList.add(styles.formError);
    }

    // If the form is a Simple Sign Up, add an attribute to the Simple Sign Up for styling purposes after the form has been submitted and processed
    if (this.isSimpleSignUp) container.setAttribute("formSubmitted", "");

    setTimeout(() => {
      // This wasn't working for the simple-sign-up, but if we add a small delay it works fine. Ideally look into a more dynamic fix in the future
      scrollIntoViewIfNeeded(formSubmittedMessage, {
        behavior: "smooth",
        block: "center"
      });
    }, 10);
  }

  static createReport = (input) => {
    if (input.dataset.isRequired === "true" || input.required) {
      const isSelectGroup = input.dataset.type === "select-group"; // Grab different label values depending on if it's a select-group or normal input
      let label = "";
      if (input.checkValidity?.()) {
        const nearestFormInput = input.closest(
          `.${formStyles.formInputs}:not([hide-verification-display])`
        );
        label = isSelectGroup
          ? input?.getValues?.()
          : nearestFormInput?.getValues?.();
        return { label };
      } else {
        label = `[${
          isSelectGroup
            ? input.querySelector("[analytics-title]")?.innerText
            : input?.parentElement?.querySelector("label")?.innerText
        }]`;
      }
      return { label, error: true };
    } else return {};
  };

  static getCampaignIds(formData) {
    const mdCampaign = document.getElementById("mdCampaign");
    formData.push(["MDCampaignId", mdCampaign?.dataset?.mdcampaignid || 10]);
    formData.push(["boncomCampaignId", SteppedForm.getBoncomCid() || 0]);
  }

  static simpleSignUpDataAlteration(dataObj) {
    const phoneNumber =
      dataObj.phone && dataObj.phoneCountryCode
        ? decodeURIComponent(dataObj.phoneCountryCode) + " " + dataObj.phone
        : null;
    const phone = phoneNumber
      ? {
          phoneNumber,
          whatsAppable: dataObj?.phoneFieldType === "whatsapp"
        }
      : undefined;
    const email = dataObj.email
      ? {
          emailAddress: decodeURIComponent(dataObj.email)
        }
      : undefined;
    const channel = (phoneNumber && dataObj?.phoneFieldType) || "email";

    return {
      person: { firstName: dataObj.fullName },
      phone,
      email,
      countryIso2: (phoneNumber && dataObj.phoneCountry) || dataObj.countryIso2,
      languageIso3: dataObj.lang,
      ...(dataObj?.missionaries &&
        dataObj?.address &&
        dataObj?.timeSelection && {
          messagingMergeCodes: {
            missionaries: dataObj?.missionaries || "",
            address: dataObj?.address || "",
            timeSelection: dataObj?.timeSelection || ""
          }
        }),
      subscriptions: [
        {
          channel,
          cid: SteppedForm.getBoncomCid() || 0,
          clientRegion: dataObj.clientRegion,
          listId: dataObj.sourceId,
          siteUrl: window.location.href,
          subscribed: true
        }
      ]
    };
  }
}

export const init = () => {
  initComponent("stepped-form", (element) => new SteppedForm(element));
};
init();
